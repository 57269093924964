<template>
    <div
        class="workshop"
        :class="{block: isView, disable: disableAll, isReview: isDocumentReview, isWorkshop, isFullScreen}"
    >
        <div
            v-if="documentName"
            class="document-name"
        >
            {{ documentName }}
        </div>
        <div
            class="board"
            :class="{fullScreen: isFullScreen || isView}"
        >
            <div
                v-if="isDocumentReview || isView"
                class="review-tools"
            >
                <div
                    class="app-tool-btn accent"
                    title="Zoom +"
                    @click="zoomRdf(true)"
                >
                    <i class="fa fa-search-plus"></i>
                </div>
                <div
                    class="app-tool-btn accent"
                    title="Zoom -"
                    @click="zoomRdf(false)"
                >
                    <i class="fa fa-search-minus"></i>
                </div>
            </div>
            <div
                v-if="!isDocumentReview"
                class="board__tools"
            >
                <div
                    class="app-tool-btn pre accent"
                    :title="$t('previous', 'Previews')"
                    :class="{ disabled: pdf.currentPage === 1 }"
                    @click="switchPage(pdf.currentPage-1)"
                >
                    <img src="@/assets/img/icons/tools/arrow-l_icon.svg" />
                </div>
                <div
                    v-if="!hiddenTools"
                    class="center"
                >
                    <div
                        class="app-tool-btn forDesktop accent"
                        title="Start/stop demonstration"
                        :class="{ red: isModerator, disabled: otherModerator }"
                        @click="startModerator"
                    >
                        <img src="@/assets/img/icons/tools/cursor_icon.svg" />
                    </div>
                    <div
                        class="app-tool-btn accent"
                        title="Hide/show other labels"
                        :class="{ red: hiddenOtherObjects }"
                        @click="toggleOtherObjects"
                    >
                        <img src="@/assets/img/icons/tools/clear_icon.svg" />
                    </div>
                    <div
                        class="app-tool-btn accent"
                        :class="{ active: activeTool === 6 }"
                        title="Start/stop draw mode"
                        @click="activateTool(6)"
                    >
                        <PencilIcon class="white" />
                    </div>
                    <div
                        class="app-tool-btn"
                        :class="{ active: activeTool === 3 }"
                        title="Set check"
                        @click="activateTool(3)"
                    >
                        <img src="@/assets/img/icons/tools/check_icon.svg" />
                    </div>
                    <div
                        class="app-tool-btn"
                        :class="{ active: activeTool === 4 }"
                        title="Set cross"
                        @click="activateTool(4)"
                    >
                        <img src="@/assets/img/icons/tools/cross_icon.svg" />
                    </div>
                    <div
                        class="app-tool-btn"
                        :class="{ active: activeTool === 5 }"
                        title="Set question"
                        @click="activateTool(5)"
                    >
                        <img src="@/assets/img/icons/tools/question_icon.svg" />
                    </div>
                    <div
                        class="app-tool-btn"
                        :class="{ active: activeTool === 2 }"
                        title="Set arrow"
                        @click="activateTool(2)"
                    >
                        <img src="@/assets/img/icons/tools/arrow-top_icon.svg" />
                    </div>
                    <div
                        class="app-tool-btn"
                        :class="{ active: activeTool === 1 }"
                        title="Set line"
                        @click="activateTool(1)"
                    >
                        <img src="@/assets/img/icons/tools/line_icon.svg" />
                    </div>
                    <div
                        class="elements-settings"
                    >
                        <input
                            v-model="elementWeight"
                            title="Width pen"
                            type="range"
                            min="0.5"
                            max="2.5"
                            step="0.25"
                        />

                        <appColorPicker v-model="elementColor" />
                    </div>
                    <div
                        class="app-tool-btn"
                        title="Undo"
                        @click="$refs.paintBord.undoElement()"
                    >
                        <i class="fa fa-undo"></i>
                    </div>
                    <div
                        class="app-tool-btn"
                        title="Redo"
                        @click="$refs.paintBord.redoElement()"
                    >
                        <i class="fa fa-repeat"></i>
                    </div>
                    <div
                        class="app-tool-btn"
                        title="Zoom +"
                        @click="zoomRdf(true)"
                    >
                        <i class="fa fa-search-plus"></i>
                    </div>
                    <div
                        class="app-tool-btn"
                        title="Zoom -"
                        @click="zoomRdf(false)"
                    >
                        <i class="fa fa-search-minus"></i>
                    </div>
                    <div
                        class="app-tool-btn"
                        title="Add vote"
                        @click="openVotePopup"
                    >
                        <AddVoteIcon />
                    </div>
                </div>
                <div class="right">
                    <div
                        v-if="!hiddenTools"
                        class="app-tool-btn fullScreen"
                        title="Full screen"
                        @click="toggleFullScreen"
                    >
                        <FullScreenIcon v-if="!isFullScreen" />
                        <LowScreenIcon v-else />
                    </div>
                    <div
                        class="app-tool-btn next accent"
                        :title="$t('next', 'Next')"
                        :class="{ disabled: pdf.currentPage === workshop.total_page }"
                        @click="switchPage(pdf.currentPage+1)"
                    >
                        <img src="@/assets/img/icons/tools/arrow-l_icon.svg" />
                    </div>
                </div>
            </div>

            <div
                ref="board"
                v-dragscroll="false"
                class="board__work-place"
                @contextmenu.prevent
            >
                <appLoader v-if="!loadedDoc" />
                <div
                    ref="layer"
                    class="layer"
                    @dblclick="addCommentBalloon"
                >
                    <app-watermark
                        v-if="(pdfIsRendered || loadedDoc) && watermarkParams.text"
                        :watermark-params="watermarkParams"
                    />
                    <canvas ref="pdf"></canvas>
                    <paint-bord
                        v-if="pdfIsLoad && workshop && !hiddenTools"
                        ref="paintBord"
                        :tool="activeTool"
                        :element-weight="elementWeight"
                        :element-color="elementColor"
                        :socket="socket"
                        :objects="workshop.objects"
                        :pdf="$refs.pdf"
                        @updateElements="updateElements"
                    />
                    <div
                        v-if="commentsList.length && pdfIsLoad"
                        class="comments-labels"
                    >
                        <div
                            v-for="balloon in commentsList"
                            :key="balloon.id"
                        >
                            <div
                                v-if="balloon.l_x>=0"
                                class="balloon"
                                :style="{ top: `${ balloon.l_y*scalingFactor }px`, left: `${ balloon.l_x*scalingFactor }px`,
                                          transform: `scale(${ 1/pdf.zoom })`}"
                                :class="{ hover: balloon.hover }"
                                @mouseenter="toggleComment(balloon, true, true,)"
                                @mouseleave="toggleComment(balloon, false, true)"
                            >
                                <svg
                                    id="svg8"
                                    class="back"
                                    version="1.1"
                                    viewBox="0 0 185.20832 185.20832"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g
                                        id="layer1"
                                        transform="translate(244.17261,202.68451)"
                                    >
                                        <g
                                            id="g2099"
                                            transform="translate(200.98667,1559.4583)"
                                        >
                                            <path
                                                id="rect1013"
                                                stroke-width="20px"
                                                d="m -436.72159,-1743.2881 c -2.10926,0 -3.80747,1.7045 -3.80747,3.8223 v 103.5679 c 0,2.1178 1.69821,3.8231 3.80747,3.8231 h 23.24053 v 36.2859 l 71.37453,-36.2859 h 31.63448 42.08329 c 2.10914,0 3.80758,-1.7053 3.80758,-3.8231 v -103.5679 c 0,-2.1178 -1.69844,-3.8223 -3.80758,-3.8223 z"
                                                fill="white"
                                                :stroke="balloon.color"
                                            />
                                        </g>
                                    </g>
                                </svg>
                                {{ balloon.number }}
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="isModerator || (otherModerator && +moderatorPage===+pdf.currentPage)"
                        class="moderator"
                        :style="{ top: `${ moderItemTop }px`, left: `${ moderItemLeft }px` }"
                    ></div>
                </div>
            </div>

            <div class="board__bottom">
                <div
                    class="app-tool-btn pre accent"
                    :title="$t('previous', 'Previews')"
                    :class="{ disabled: pdf.currentPage === 1 }"
                    @click="switchPage(pdf.currentPage-1)"
                >
                    <img src="@/assets/img/icons/tools/arrow-l_icon.svg" />
                </div>
                <form
                    class="pages"
                    @submit.prevent="switchPage(+pageInput)"
                >
                    <span>
                        {{ pdf.currentPage }}/{{ workshop.total_page }}</span>
                    <input
                        v-model="pageInput"
                        class="app-input white"
                        type="number"
                        :placeholder="$t('go_to', 'Go to')"
                    />
                    <button
                        type="submit"
                        class="app-tool-btn go accent"
                        title="Go to"
                    >
                        {{ $t('go', 'Go') }}
                    </button>
                </form>
                <div class="right">
                    <div
                        v-if="!hiddenTools"
                        class="app-tool-btn fullScreen"
                        title="Full screen"
                        @click="toggleFullScreen"
                    >
                        <FullScreenIcon v-if="!isFullScreen" />
                        <LowScreenIcon v-else />
                    </div>
                    <div
                        class="app-tool-btn next accent"
                        :title="$t('next', 'Next')"
                        :class="{ disabled: pdf.currentPage === workshop.total_page }"
                        @click="switchPage(pdf.currentPage+1)"
                    >
                        <img src="@/assets/img/icons/tools/arrow-l_icon.svg" />
                    </div>
                </div>
            </div>
        </div>
        <div
            v-show="!isFullScreen && !isView"
            :class="{ 'active-video': videoCallIsActive }"
            class="chat"
        >
            <div
                v-show="videoCallIsActive"
                class="chat__video"
            >
                <app-video-chat
                    v-if="!isZoomVideoChat"
                    ref="appVideoChatBlock"
                    :link="videoCallLink"
                    @close="closeVideoCall"
                />
                <zoom-mtg
                    v-if="isZoomVideoChat"
                    ref="zoomMtgBlock"
                    @endMeeting="closeVideoCall"
                />
            </div>
            <div class="chat__head">
                <div class="chat__head-left">
                    <h4>{{ $t('comments', 'Comments') }}</h4>
                    <h5>{{ $t('comments_page', 'Comments for page') }} {{ comments.length }}</h5>
                </div>
                <app-language-select
                    v-if="showTranslating"
                    ref="commentLanguageSelect"
                    @change="translateComments"
                />
            </div>
            <div class="content">
                <form>
                    <label v-click-outside="deleteCreatedComment">
                        <p>{{ $t('enter_comment', 'Please, enter comment') }}:</p>
                        <textarea
                            ref="commentTextarea"
                            v-model="commentTextarea"
                            class="app-input"
                        ></textarea>
                        <app-button
                            class="accent"
                            @click="createComment"
                        >
                            {{ $t('send', 'Send') }}
                        </app-button>
                    </label>
                </form>
                <div class="chat__messages">
                    <app-comments
                        :comments="reversedComments"
                        type="short"
                        :question-id="questionId"
                        @updateComments="toggleComment"
                        @toggleCommentState="toggleCommentState"
                        @comment="openCommentPopup"
                        @edit="editPopup"
                    />
                </div>
            </div>
        </div>

        <app-popup name="workshopFunctionalityPopup">
            <functionalityPopup :is-review="!!isDocumentReview" />
        </app-popup>
        <voting
            v-if="isWorkshop"
            :surveys="surveys"
            :all-answered="allAnswered"
            @deleteSurvey="deleteSurvey"
            @answer="answerSurvey"
            @translate="translateComments($event, true)"
        />
        <app-popup
            v-if="isWorkshop"
            name="add-vote-popup"
        >
            <add-vote-popup
                :socket="socket"
                :paths="$options.surveysPaths"
                @addSurvey="addSurvey"
                @editedSurvey="editedSurvey"
            />
        </app-popup>
        <participantsChat
            v-if="role===5 && isWorkshop"
            :unread-count.sync="+workshop.unread_chat"
            :socket="socket"
            @read="readMessages"
        />
        <div
            v-if="creatingCommentBalloon"
            ref="creatingCommentBalloon"
            class="creatingCommentBalloon"
        ></div>

        <app-popup name="commentPopup">
            <comment-popup
                :paths="socketPaths"
                :socket="socket"
                @createComment="createSubComment"
            />
        </app-popup>
    </div>
</template>

<script>
import appColorPicker from '@/components/user/app/app-color-picker';
import appComments from '@/components/user/app/app-comments';
import appButton from '@/components/user/app/app-button';
import paintBord from '@/components/user/pages/workshop/paint-bord';
import appPopup from '@/components/user/app-popup';
import functionalityPopup from '@/components/user/pages/workshop/functionality-popup';
import participantsChat from '@/components/user/pages/workshop/participants-chat';
import addVotePopup from '@/components/user/pages/workshop/add-vote-popup';
import voting from '@/components/user/pages/workshop/voting';
import appWatermark from '@/components/user/app/app-watermark';
import appVideoChat from '@/components/user/app/app-video-chat';
import commentPopup from '@/components/user/app/app-comment-popup';
import appLoader from '@/components/user/app/app-loader';
import ZoomMtg from '@/components/user/pages/dashboard/zoomMtg';
import appLanguageSelect from '@/components/user/app/app-language-select';
import AddVoteIcon from '@/assets/img/icons/add_vote_icon.svg?inline';
import FullScreenIcon from '@/assets/img/icons/full_screen_icon.svg?inline';
import LowScreenIcon from '@/assets/img/icons/low-screen_icon.svg?inline';
import PencilIcon from '@/assets/img/icons/tools/pencil_icon.svg?inline';

import '@/assets/js/canvasArrows.js';
import vClickOutside from 'v-click-outside';

import { GP, socketInstance } from '@/utils/sockets-helper';
import { dragscroll } from 'vue-dragscroll';
import { bus } from '@/main';
import { get, post } from '@/utils/http-helper';
import surveys from '@/utils/mixins/surveys';

const pdfjsLib = require('pdfjs-dist');
const GET_FILE = '/files/get-file';
const GET_FILE_INFO = '/files/get-info';
const NAME_SPACE = 'workshop';
const MODERATOR_START = 'moderator-start';
const MODERATOR_MOUSE = 'moderator-mouse';
const MODERATOR_PAGE = 'moderator-page';
const FINISH_MODERATOR = 'moderator-stop';
const CREATE_COMMENT = 'comment-create';
const EDIT_COMMENT = 'comment-edit';
const REMOVE_COMMENT = 'comment-remove';
const RESTORE_COMMENT = 'comment-restore';
const GET_PAGE = '/workshop/get-page';
const GET_REVIEW_PAGE = '/review/get-page';
const TRANSLATE = 'translate';

const SWITCH_PAGE = 'page-switch';

export default {
    name: 'app-workshop',

    components: {
        appColorPicker,
        appComments,
        paintBord,
        appButton,
        appPopup,
        functionalityPopup,
        participantsChat,
        addVotePopup,
        voting,
        appWatermark,
        appVideoChat,
        commentPopup,
        appLoader,
        appLanguageSelect,
        ZoomMtg,
        AddVoteIcon,
        FullScreenIcon,
        LowScreenIcon,
        PencilIcon,
    },

    directives: {
        dragscroll,
        clickOutside: vClickOutside.directive,
    },

    mixins: [surveys(NAME_SPACE)],

    surveysPaths: {
        NAME_SPACE: 'workshop',
        CREATE_SURVEY: 'survey-create',
        UPDATE_SURVEY: 'survey-update',
    },

    props: {
        isView: {
            type: Boolean,
            default: false,
        },

        isDocumentReview: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        workshop: {},

        comments: [],
        createdComment: null,
        commentTextarea: '',
        scalingFactor: '',
        creatingCommentBalloon: false,

        documentName: '',
        pdfIsRendered: false,
        loadedDoc: false,

        surveys: [],

        pdf: {
            pdf: null,
            currentPage: 1,
            numPages: null,
            zoom: 1,
        },

        isModerator: false,
        otherModerator: false,
        moderItemTop: 0,
        moderItemLeft: 0,
        hiddenOtherObjects: false,
        moderatorPage: 1,

        pageInput: '',
        file: null,
        pdfIsLoad: false,

        activeTool: null,
        elementWeight: '1',
        elementColor: '#000000',

        videoCallIsActive: false,
        videoCallLink: '',
        isFullScreen: false,

        socket: null,
        generateSocketPath: null,
    }),

    computed: {
        reversedComments() {
            return [...this.comments].reverse();
        },
        socketPaths() {
            if (!this.isDocumentReview) {
                return {
                    NAME_SPACE: 'workshop',
                    CREATE_TEXT: 'comment-create',
                };
            }
            return {
                NAME_SPACE: 'review',
                CREATE_TEXT: 'comment-create',
            };
        },
        topicId() {
            return this.$route.query.id;
        },
        eventId() {
            return this.$route.query.event_id;
        },
        questionId() {
            return this.$route.query.question_id || 0;
        },
        documentId() {
            return this.$route.query.document_id;
        },
        part() {
            return this.$route.query.part;
        },
        type() {
            return this.$route.query.type;
        },
        workshopData() {
            return this.$store.getters['workshop/get'];
        },
        userId() {
            return this.$store.getters['auth/userInfo'].id;
        },
        userColor() {
            return this.$store.getters['auth/userInfo'].color;
        },
        commentsList() {
            let comments = [];
            this.comments ? comments = [...this.comments] : null;
            this.createdComment ? comments.push(this.createdComment) : null;
            return comments;
        },
        role() {
            return this.$store.getters['auth/userInfo']?.role.id;
        },
        hiddenTools() {
            return this.isView;
        },
        watermarkParams() {
            return {
                text: this.workshop?.info?.user_text,
                w: this.$refs.pdf?.width,
                h: this.$refs.pdf?.height,
            };
        },

        isWorkshop() {
            return !this.isView && !this.isDocumentReview;
        },

        disableAll() {
            const mode = +this.$store.getters['auth/userInfo']?.role.mode;
            return this.role !== 5 && this.role !== 6 || mode === 0;
        },

        showTranslating() {
            return this.$store.getters['platform/platform'].settings.translating;
        },

        isZoomVideoChat() {
            return this.videoCallLink.includes('zoom');
        },
    },

    created() {
        bus.$on('startVideoCall', (data) => {
            if (data.isMain) {
                this.videoCallLink = this.workshop?.info.video_main;
            } else {
                this.videoCallLink = this.workshop?.info.video_context;
            }

            this.videoCallIsActive = data.status;

            if (this.isZoomVideoChat) {
                this.$nextTick(() => {
                    if (this.$refs.zoomMtgBlock) {
                        this.$refs.zoomMtgBlock.joinMeeting(this.videoCallLink);
                    }
                });
            } else {
                this.$nextTick(() => {
                    if (this.$refs.appVideoChatBlock) {
                        this.$refs.appVideoChatBlock.initChat();
                    }
                });
            }

        });
        this.initSockets();
    },

    async mounted() {
        if (this.topicId) {
            let part = 'workshop';

            if (this.isDocumentReview) {
                await this.$store.dispatch('workshop/getReview', this.topicId);
                part = 'review';
            } else {
                await this.$store.dispatch('workshop/get', this.topicId);
            }
            this.getDocument({
                part,
                topic_id: this.topicId,
                event_id: this.eventId,
                question_id: this.questionId,
            });
            this.workshop = this.workshopData;
            this.setWorkshopInfo();
        }
        if (this.documentId && this.part) {
            const requestObj = {
                part: this.part,
                obj_id: this.documentId,
                event_id: this.eventId,
                question_id: this.questionId,
            };
            await this.getDocument(requestObj);
            this.getDocumentInfo(requestObj);
        }
        if (this.type && this.part) {
            const requestObj = {
                type: this.type,
                part: this.part,
                event_id: this.eventId,
                question_id: this.questionId,
            };
            await this.getDocument(requestObj);
            this.getDocumentInfo(requestObj);
        }
    },

    beforeDestroy() {
        this.socket.destroy();
    },

    methods: {
        editPopup(commentId = 0, questionId = 0, commentContent = '') {
            const options = {
                commentId,
                questionId,
                commentContent,
                editing: true,
            };
            this.$store.dispatch('togglePopup', { popupName: 'commentPopup', options });
        },
        activateTool(num) {
            if (this.activeTool === num) {
                this.activeTool = null;
            } else {
                this.activeTool = num;
            }
        },
        initSockets() {
            this.generateSocketPath = new GP(this.socketPaths.NAME_SPACE);

            this.socket = socketInstance(this.socketPaths.NAME_SPACE, {
                room: `room-${this.topicId}-${this.questionId || 0}`,
            });
            this.socket.on(this.generateSocketPath.generate(CREATE_COMMENT), data => {
                if (data.success) {
                    if (data.l_x === -1) {
                        data.color = null;
                    }
                    data.count_comments = 0;
                    data.comments = [];
                    if (data.parent_id) {
                        const parent = this.comments.find(c => +c.id === +data.parent_id);
                        parent.comments.push(data);
                        parent.count_comments++;
                    } else {
                        this.comments.push(data);
                    }
                }
            });
            this.socket.on(this.generateSocketPath.generate(EDIT_COMMENT), data => {
                if (data.success) {
                    let comment = this.comments.find(c => c.id === data.comment_id);
                    if (!comment) {
                        this.comments.forEach(c => {
                            if (!comment) {
                                comment = c.comments.find(c => c.id === data.comment_id);
                            }
                        });
                    }
                    comment.content = data.content;
                }
            });
            this.socket.on(this.generateSocketPath.generate(REMOVE_COMMENT), data => {
                if (data.success) {
                    let comment = this.comments.find(c => c.id === data.comment_id);
                    if (!comment) {
                        this.comments.forEach(c => {
                            if (!comment) {
                                comment = c.comments.find(c => c.id === data.comment_id);
                            }
                        });
                    }
                    comment.deleted = 1;
                    comment.content = data.content;
                }
            });
            this.socket.on(this.generateSocketPath.generate(RESTORE_COMMENT), data => {
                if (data.success) {
                    let comment = this.comments.find(c => c.id === data.comment_id);
                    if (!comment) {
                        this.comments.forEach(c => {
                            if (!comment) {
                                comment = c.comments.find(c => c.id === data.comment_id);
                            }
                        });
                    }
                    comment.deleted = 0;
                    comment.content = data.content;
                }
            });
            this.socket.on(this.generateSocketPath.generate(MODERATOR_START), data => {
                if (data.success) {
                    this.switchPage(data.page);
                    this.moderatorPage = data.page;
                    this.otherModerator = true;
                }
            });
            this.socket.on(this.generateSocketPath.generate(MODERATOR_MOUSE), data => {
                if (data.success) {
                    this.otherModerator = true;
                    this.moderItemTop = data.position.y * this.scalingFactor;
                    this.moderItemLeft = data.position.x * this.scalingFactor;
                }
            });
            this.socket.on(this.generateSocketPath.generate(MODERATOR_PAGE), data => {
                if (data.success) {
                    this.switchPage(data.page);
                    this.moderatorPage = data.page;
                }
            });
            this.socket.on(this.generateSocketPath.generate(FINISH_MODERATOR), data => {
                if (data.success) {
                    this.otherModerator = false;
                }
            });
            this.subscribeSocketEvents();
        },
        async getDocument(requestData) {
            const response = await get(GET_FILE, requestData);
            if (response) {
                this.file = response;
            }
            this.parseDocument();
        },
        async getDocumentInfo(requestData) {
            const response = await get(GET_FILE_INFO, requestData);
            if (response.status) {
                this.documentName = response.data.name;
                this.workshop.total_page = response.data.total_page;
                this.workshop.info = response.data.info;
            }
        },
        async parseDocument() {
            /* eslint-disable */
            const pdfAsDataUri = `data:application/pdf;base64,${this.file}`;
            pdfjsLib.getDocument(pdfAsDataUri).then(async (pdf) => {
                this.pdf.pdf = pdf;
                this.pdf.numPages = pdf.numPages;
                await this.renderPdf();

                if (this.$route.query.page) {
                    setTimeout(() => {
                        this.switchPage(+this.$route.query.page);
                    }, 100);
                }
            }).catch(e => {
                console.error('parseDocument error', e);
            });
            /* eslint-enable */
        },
        async renderPdf() {
            await this.pdf.pdf.getPage(this.pdf.currentPage).then((page) => {
                const cnv = this.$refs.pdf;
                const ctx = cnv.getContext('2d');

                const viewport = page.getViewport(this.pdf.zoom);
                const width = this.$refs.layer.offsetWidth;
                let scale = width / viewport.width;
                this.pdf.minZoom = scale;
                const scaledViewport = page.getViewport(scale);

                cnv.width = scaledViewport.width;
                cnv.height = scaledViewport.height;

                if (this.isView) {
                    cnv.width = scaledViewport.width - 17;
                }

                page.render({
                    canvasContext: ctx,
                    viewport: scaledViewport,
                });
                this.pdfIsLoad = true;

                this.$nextTick(() => {
                    this.scalingFactor = width / 1000 / this.pdf.zoom;
                    if (!this.hiddenTools && !this.isDocumentReview) {
                        this.$refs.paintBord.initCanvas(scale, viewport.width);
                        this.pdfIsRendered = true;
                    }
                    this.loadedDoc = true;
                });
            }).catch(e => {
                console.error('renderPdf error', e);
            });
        },

        zoomRdf(up) {
            if (!this.pdf.pdf) return;
            if (up) {
                this.pdf.zoom >= .6 ? this.pdf.zoom -= 0.1 : this.pdf.zoom = .5;
            } else {
                this.pdf.zoom <= .9 ? this.pdf.zoom += 0.1 : this.pdf.zoom = 1;
            }
            this.comments = this.comments.map(c => {
                c.x = c.l_x / this.pdf.zoom;
                c.y = c.l_y / this.pdf.zoom;
                return c;
            });
            this.renderPdf();
        },
        startModerator() {
            if (this.isModerator) {
                this.finishModerator();
                return;
            }
            this.$confirm({
                message: `${this.$t('popup_start_md', 'Are you sure to start moderation?')}`,
                button: {
                    no: `${this.$t('dash_no', 'No')}`,
                    yes: `${this.$t('dash_yes', 'Yes')}`,
                },
                callback: confirm => {
                    if (confirm) {
                        this.socket.emit(this.generateSocketPath.generate(MODERATOR_START), {}, data => {
                            if (data.success) {
                                this.isModerator = true;
                                this.$refs.board.onmousemove = (e) => {
                                    if (e.target.closest('.board__work-place')) {
                                        const LX = this.$refs.board.getBoundingClientRect().left - this.$refs.board.scrollLeft;
                                        const LY = this.$refs.board.getBoundingClientRect().top - this.$refs.board.scrollTop;
                                        this.moderItemTop = (e.clientY - LY);
                                        this.moderItemLeft = (e.clientX - LX);
                                    }
                                };
                                window.moderatorMouseI = setInterval(() => {
                                    this.socket.emit(this.generateSocketPath.generate(MODERATOR_MOUSE), {
                                        position: {
                                            x: this.moderItemLeft / this.scalingFactor,
                                            y: this.moderItemTop / this.scalingFactor,
                                        },
                                    });
                                }, 10);
                            }
                        });
                    }
                },
            });
        },
        finishModerator() {
            this.$confirm({
                message: `${this.$t('popup_stop_md', 'Are you sure to stop moderation?')}`,
                button: {
                    no: `${this.$t('dash_no', 'No')}`,
                    yes: `${this.$t('dash_yes', 'Yes')}`,
                },
                callback: confirm => {
                    if (confirm) {
                        this.socket.emit(this.generateSocketPath.generate(FINISH_MODERATOR), {}, data => {
                            if (data.success) {
                                this.isModerator = false;
                                clearInterval(window.moderatorMouseI);
                                this.$refs.board.onmousemove = null;
                            }
                        });
                    }
                },
            });
        },
        toggleOtherObjects() {
            if (!this.hiddenOtherObjects) {
                this.hiddenOtherObjects = true;
                this.workshop.objects = this.workshop.objects?.map(o => {
                    if (+o.user_id !== +this.userId) {
                        o.isShow = false;
                    }
                    return o;
                });
            } else {
                this.hiddenOtherObjects = false;
                this.workshop.objects = this.workshop.objects?.map(o => {
                    if (!o.isShow) {
                        o.isShow = true;
                    }
                    return o;
                });
            }
            this.$refs.paintBord.draw();
        },

        switchPage(page) {
            if (!page) return;
            page < 1 ? page = this.pageInput = 1 : null;
            if (page > this.workshop?.total_page) {
                page = this.pageInput = this.workshop?.total_page;
            }
            if (!this.hiddenTools) {
                this.socket.emit(this.generateSocketPath.generate(SWITCH_PAGE), { page }, data => {
                    if (data.success) {
                        this.pdf.currentPage = page;
                        this.renderPdf();
                        this.pageInput = '';
                        this.getPageContent(page);
                    }
                });
            } else {
                this.pdf.currentPage = page;
                this.renderPdf();
                this.pageInput = '';
            }
            this.$refs.commentLanguageSelect.reset();
            if (+this.$route.query.page !== +page) {
                this.$router.replace({ query: { ...this.$route.query, page } });
            }
        },
        setWorkshopInfo() {
            this.comments = this.workshopData?.comments?.map(c => {
                c.hover = false;
                return c;
            });
            this.surveys = this.workshopData?.surveys;
            const objects = this.workshopData?.objects?.map(o => {
                o.isShow = true;
                return o;
            });
            this.workshop = { ...this.workshop, objects };
            this.allAnswered = !!this.workshopData?.is_all_answered;
            if (this.$refs.paintBord) {
                this.$nextTick(() => {
                    this.$refs.paintBord.draw();
                });
            }
        },
        updateElements(elements) {
            this.workshop.objects = [...elements];
        },
        async getPageContent(page) {
            let path = GET_PAGE;
            this.isDocumentReview ? path = GET_REVIEW_PAGE : null;
            await this.$store.dispatch('workshop/getPage', {
                topicId: this.topicId,
                question_id: this.questionId || this.$route.query.question_id || undefined,
                page,
                path,
            });
            this.setWorkshopInfo();
        },
        toggleFullScreen() {
            this.isFullScreen = !this.isFullScreen;
            setTimeout(() => {
                this.renderPdf();
            });
        },
        openVotePopup() {
            this.$store.dispatch('togglePopup', { popupName: 'add-vote-popup' });
        },
        addCommentBalloon(e) {
            const comment = {
                l_x: e.layerX / this.scalingFactor,
                l_y: e.layerY / this.scalingFactor - 20,
                hover: false,
                color: this.userColor,
            };
            this.createdComment = comment;
            this.$refs.commentTextarea.focus();
        },
        createComment() {
            if (!this.commentTextarea) return;
            this.sendComment();
        },
        sendComment() {
            if (!this.commentTextarea) return;
            const comment = {
                content: this.commentTextarea,
                l_x: Math.round(this.createdComment?.l_x) || -1,
                l_y: Math.round(this.createdComment?.l_y) || -1,
                hover: this.createdComment?.hover,
            };
            this.socket.emit(this.generateSocketPath.generate(CREATE_COMMENT), comment, data => {
                if (data.success) {
                    if (data.l_x === -1) {
                        data.color = null;
                    }
                    data.comments = [];
                    data.count_comments = 0;
                    this.comments.push(data);
                    this.commentTextarea = '';
                    this.createdComment = null;
                }
            });
        },
        openCommentPopup(parentId = 0, toAll = 0, questionId = 0) {
            const options = {
                topicId: this.topicId,
                parentId,
                toAll,
                questionId,
            };
            this.$store.dispatch('togglePopup', { popupName: 'commentPopup', options });
        },
        createSubComment(data) {
            const comment = this.comments.find(c => +c.id === +data.parent_id);
            comment.comments.push(data);
            comment.count_comments++;
        },
        toggleComment(com, state, isBalloon) {
            const comments = this.comments;
            const comment = comments.find(c => com.id === c.id);
            if (comment) comment.hover = state;
            this.comments = [...comments];

            setTimeout(() => {
                if (this.isWorkshop) return;
                if (isBalloon) {
                    const commentsList = document.querySelector('.chat__messages .app-comments');
                    const commentInList = document.querySelector('.app-comments__discuss.hover');
                    if (!commentInList) return;
                    commentsList.scrollTo({
                        top: commentInList?.offsetTop - commentsList.getBoundingClientRect().top - pageYOffset,
                        behavior: 'smooth',
                    });
                } else {
                    const board = this.$refs.board;
                    const balloon = document.querySelector('.balloon.hover');
                    if (!balloon) return;
                    board.scrollTo({
                        top: balloon?.offsetTop - board.getBoundingClientRect().top - pageYOffset,
                        behavior: 'smooth',
                    });
                }

            }, 100);
        },
        toggleCommentState(id, deleted) {
            let url = REMOVE_COMMENT;
            if (deleted) {
                url = RESTORE_COMMENT;
            }
            this.socket.emit(this.generateSocketPath.generate(url), {
                comment_id: id,
            }, data => {
                if (data.success) {
                    let comment = this.comments.find(c => c.id === id);
                    if (!comment) {
                        this.comments.forEach(c => {
                            if (!comment) {
                                comment = c.comments.find(c => c.id === id);
                            }
                        });
                    }
                    comment.deleted = !deleted;
                    comment.content = data.content;
                }
            });
        },
        deleteCreatedComment() {
            if (this.createdComment) {
                this.createdComment = null;
            }
        },
        readMessages(num) {
            this.workshop.unread_chat = num;
        },

        closeVideoCall() {
            this.videoCallIsActive = false;
            bus.$emit('endVideoCall');
        },

        async translateComments(lang, surveys) {
            if (lang) {
                const response = await post(`${this.socketPaths.NAME_SPACE}/${TRANSLATE}`, {
                    topic_id: +this.topicId,
                    page: +this.pdf.currentPage,
                    question_id: this.questionId || undefined,
                    lang,
                });
                if (response.status) {
                    if (!surveys) {
                        this.comments = this.comments.map(c => {
                            c.content = response.data.comments.find(t => t.id === c.id)?.content || c.content;
                            c.comments = c.comments.map(cc => {
                                cc.content = response.data.comments.find(t => t.id === cc.id)?.content || cc.content;
                                return cc;
                            });
                            return c;
                        });
                    } else {
                        this.surveys = this.surveys.map(c => {
                            const survey = response.data.surveys.find(t => t.id === c.id);
                            c.name = survey?.name || c.name;
                            c.options = survey?.options || c.options;
                            return c;
                        });
                    }
                }
            } else {
                this.getPageContent(+this.pdf.currentPage);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.workshop {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    padding: 2.4rem 0;

    .go {
        width: auto;
        min-width: 2.5rem;
    }

    &.disable {
        pointer-events: none;

        .next, .pre, .fullScreen, .board__work-place, .chat__messages, .board__bottom, .app-popup {
            pointer-events: all;
        }

        &::v-deep {
            .app-comments__comment * {
                pointer-events: none;
            }

            .app-comments__comment {
                .date span {
                    pointer-events: all;
                }
            }
        }
    }

    &.block {
        display: block;

        .board__tools {
            padding: 0.2rem 1rem;
        }

        .board__work-place {
            height: 40rem;
        }
    }

    &.isReview {
        .board {
            height: calc(85vh - 1px);
        }

        &.isFullScreen {
            height: initial;
        }
    }

    &.isReview {
        align-items: initial;

        .board {
            display: flex;
            flex-direction: column;

            &__work-place {
                height: 100%;
            }
        }

        .chat__messages {
            max-height: 59vh;
        }
    }

    .moderator {
        position: absolute;
        z-index: 1000000;

        width: 10px;
        height: 10px;

        background: $red;
        border-radius: 50%;

        transform: translate(-50%, -50%);

        pointer-events: none;
    }
}

.document-name {
    margin-bottom: 2rem;

    font-size: to_rem(16px);
    text-align: center;
    color: $gray;
}

.watermarkText {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
    opacity: 0.5;
}

.creatingCommentBalloon {
    position: fixed;

    width: 20px;
    height: 20px;

    background: url("~@/assets/img/icons/review_subcomm_gold_icon.svg") no-repeat center center/cover;

    transform: translateY(-50%);
}

.app-tool-btn {
    &::v-deep {
        svg {
            path {
                fill: var(--platform-main-color);
            }
        }

        svg.white {
            path {
                fill: #fff;
            }

            .cl-1 {
                stroke: var(--platform-accent-color);
            }
        }
    }
}

.board {
    position: relative;

    border: 20px solid var(--platform-main-color);
    border-bottom: none;

    &.fullScreen {
        width: 100%;
        height: fit-content !important;

        .layer {
            width: 100%;
        }

        .board__work-place {
            height: 75vh;
        }
    }

    &__tools {
        display: flex;
        justify-content: space-between;

        padding: 1.95rem 0.7rem;

        background: $mainBackgroundColor;

        .center {
            display: flex;
        }

        .elements-settings {
            display: flex;
            align-items: center;
        }
    }

    &__work-place {
        position: relative;

        height: 468px;
        overflow: auto;

        .comments-labels {
            position: absolute;
            top: 0;
            left: 0;

            .balloon {
                position: absolute;
                z-index: 100000;

                display: flex;
                justify-content: center;
                align-items: center;

                width: 20px;
                height: 20px;
                padding-bottom: 0.25rem;

                font-size: to_rem(10px);
                font-weight: 700;

                //background: url("~@/assets/img/icons/review_subcomm_gold_icon.svg") no-repeat center center/cover;

                transform-origin: bottom left;
                cursor: pointer;

                user-select: none;

                &.hover {
                    transform: scale(1.2) !important;
                    transform-origin: left bottom;

                    will-change: transform;
                }

                .back {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: -1;

                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 0.5rem 0;

        background: var(--platform-main-color);

        .pages {
            display: flex;
            align-items: center;

            color: white;

            input {
                width: 4.5rem;
                margin: 0 0.5rem;
            }

            button {
                border: none;
            }
        }
    }

    .right {
        display: flex;

        & > div {
            margin-left: 0.5rem;
        }

        .fullScreen {
            background: white;
        }
    }

    .layer {
        position: relative;

        width: to_rem(843px);
        margin: 0 auto;
    }

    .review-tools {
        position: absolute;
        top: 4px;
        left: 50%;
        z-index: 500;

        display: flex;
        justify-content: center;
        align-items: center;

        transform: translateX(-50%);

        div {
            margin: 0 5px;

            opacity: 0.5;
            transition: opacity 0.2s;

            &:hover {
                opacity: 1;
            }
        }
    }
}

.isWorkshop {
    .chat {
        height: calc(470px + 20px + 5.8rem + 2.9rem - 1px);
    }
}

.zoom-mtg {
    &::v-deep {
        #zoom-sdk-video-canvas {
            height: 250px !important;
        }
    }
}

.chat {
    display: flex;
    flex-direction: column;

    width: 32.6rem;
    height: 85vh;
    margin-left: 1rem;

    &.active-video {
        height: auto !important;

        .chat {
            &__messages {
                height: 25.5vh;
            }
        }

        .content {
            border-top: 1px solid $gray;
        }

        form {
            margin-top: 0;

            textarea {
                height: 2.5rem;
                margin: .5rem 0;
            }
        }
    }

    &__video {
        margin-bottom: 1rem;

        img {
            width: 100%;
        }
    }

    &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 1.1rem;

        font-size: to_rem(20px);
        color: $mainTextColor;
        letter-spacing: -0.05rem;

        background: var(--platform-main-color);

        h5 {
            font-size: to_rem(14px);
            font-weight: 400;
            letter-spacing: 0;
        }
    }

    .content {
        display: flex;
        flex-direction: column;

        height: calc(100% - 4.7rem);

        background: white;
        border: 1px solid $gray;
        border-top: none;
    }

    &__messages {
        height: 100%;
        overflow: auto;
    }

    form {
        padding: 1rem;

        p {
            font-size: to_rem(14px);
        }

        textarea {
            display: block;

            height: 4rem;
            margin: 1rem 0 0.75rem;

            resize: none;
        }

        .app-button {
            margin-left: auto;
        }
    }
}

.zoom-mtg {
    margin: 0;
}


input[type='range'] {
    height: 16px;
    overflow: hidden;

    cursor: pointer;
}

input[type='range'],
input[type='range']::-webkit-slider-runnable-track,
input[type='range']::-webkit-slider-thumb {
    background: transparent;

    -webkit-appearance: none;
}

input[type='range']::-webkit-slider-runnable-track {
    height: 8px;

    background: #AAA;
}

input[type='range']::-webkit-slider-thumb {
    position: relative;

    width: 16px;
    height: 16px;
    margin-top: -4px;

    background: var(--platform-accent-color);
    border-radius: 50%;
}

input[type='range']::-webkit-slider-thumb::before {
    content: '';
    position: absolute;
    top: 8px; /* don't change this */
    left: -502px; /* this should be -2px - width */

    width: 500px; /* make this bigger than the widest range input element */
    height: 10px; /* equal to height of runnable track */

    background: #777;
}


@include razr1536 {
    .chat {
        width: 32rem;
    }
}

@include mobile_or_P {
    .workshop {
        flex-direction: column;

        padding: 0 0.7rem;

        &.isReview, &.isWorkshop {
            .board {
                height: initial;
            }

            height: initial;
        }

        &.isReview {
            .board__work-place {
                height: 470px;
            }
        }

        .board {
            width: 100%;

            border-width: 17px;

            &__work-plase {
                overflow: auto;
            }

            &__tools {
                padding: 1.45rem 0.6rem;

                .app-tool-btn {
                    width: 1.5rem;
                    height: 1.5rem;
                    margin: 0 0.25rem;
                }

                .app-color-picker {
                    width: 1.5rem;
                    height: 1.5rem;
                }

                .next, .pre {
                    width: 1.6em;
                }

                .elements-settings {
                    input {
                        width: 8.5rem;
                    }
                }
            }

            &__bottom {
                .next, .pre {
                    width: 1.6em;
                }
            }

            .fullScreen {
                display: none;
            }
        }

        .chat {
            width: 69.9%;
            height: 50rem;
            margin-top: 1.3rem;
            margin-left: 0;

            &__messages {
                max-height: initial !important;
            }
        }
    }
}

@include razr767 {
    .workshop {
        padding: 0;

        .board {
            border-width: 10px;
            border-top-width: 22px;

            &__tools {
                position: relative;

                padding: 4rem .5rem 2rem;

                .pre, .next {
                    position: absolute;
                    top: 1rem;
                    left: 1rem;
                }

                .next {
                    right: 1rem;
                    left: auto;
                }

                .center {
                    flex-wrap: wrap;
                    justify-content: space-between;

                    padding: 0;
                }

                .app-tool-btn {
                    width: 2.1rem;
                    height: 2.1rem;
                    margin: 0 0 .5rem;
                }
            }
        }

        .chat {
            width: 100%;

            &__head {
                h4 {
                    font-size: 16px;
                }

                h5 {
                    font-size: 12px;
                }
            }

            form {
                margin-top: 0;

                p {
                    font-size: 14px;
                }

                textarea {
                    min-height: 7rem;
                }
            }
        }
    }
}
</style>
