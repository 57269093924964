<template>
    <label class="disposer-app-label" :style="{ width }">
        {{ title }}
        <input
            v-model="state"
            class="disposer-app-input country-input"
            :type="type"
            :disabled="disabled"
            :name="name"
            @focus="$emit('focus')"
        />
        <span
            v-if="requiredError"
            class="file-error app-input-error"
        >
            Enter {{ title }}
        </span>
        <span
            v-for="(error, index) in activeExtensionErrors"
            :key="index"
            class="file-error app-input-error"
        >
            {{ error.text }}
        </span>
        <span
            v-if="errors"
            class="file-error app-input-error"
        >
            {{ errors[0] }}
        </span>
    </label>
</template>

<script>
export default {
    name: 'app-input',

    props: {
        value: {
            type: null,
            required: true,
            default: null,
        },
        type: {
            type: String,
            default: 'text',
        },
        title: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        extensionValidations: {
            type: Array,
            default: () => [],
        },
        extensionErrors: {
            type: Object,
            default: () => {
            },
        },
        errors: {
            type: Array,
            default: () => [],
        },
        requiredError: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        width: {
            type: String,
        },
    },

    computed: {
        state: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('input', value);
            },
        },

        activeExtensionErrors() {
            return this.extensionValidations.filter(v => this.extensionErrors[v.validator]);
        },
    },
};
</script>
