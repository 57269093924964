<template>
    <div class="disposer-participants">
        <eventNav />

        <div class="disposer-app-title">
            {{ isExternal ? 'External' : 'Internal' }} Participants
        </div>

        <div class="main">
            <label class="disposer-app-checkbox">
                <input
                    v-model="showActivity"
                    type="checkbox"
                    name="updateMethod"
                    @change="toggleParticipantsActiveTime"
                />
                <div class="box"></div>
                Show active participants time
            </label>
            <div class="ext-participants-btns top">
                <div class="btn">
                    <app-button
                        tag="router-link"
                        :to="{name: `add-${pathPrefix}-participant`}"
                        class="add-participant-btn"
                    >
                        Add Participants
                    </app-button>
                </div>
                <!-- Поиск -->
                <div class="search-bar">
                    <form @submit.prevent="searchParticipants()">
                        <input
                            v-model="query"
                            type="text"
                            class="app-input"
                            placeholder="Search by name, last name, or email"
                        />
                        <span @click="searchParticipants(true)">&#10006;</span>
                    </form>
                </div>
            </div>
            <div class="participants-table">
                <div class="row-tr row-tr-head">
                    <div class="number">
                        Number
                    </div>

                    <div class="first-name">
                        First name
                    </div>

                    <div class="last-name">
                        Last name
                    </div>

                    <div class="email">
                        Email
                    </div>

                    <div class="section">
                        Section
                    </div>

                    <div class="active-inactive active-inactive-btns-head">
                        <app-button
                            @click="toggleAllStatus(1)"
                        >
                            Active All
                        </app-button>

                        <app-button
                            @click="toggleAllStatus(0)"
                        >
                            Inactive All
                        </app-button>
                    </div>

                    <div class="edit-delete">
                        Edit / Delete
                    </div>
                </div>

                <div
                    v-for="participant in participantsList"
                    :key="participant.id"
                    class="row-tr"
                >
                    <div class="number">
                        <div class="current-number">
                            <div class="number-input">
                                <vue-numeric-input
                                    v-model="participant.order"
                                    :min="0"
                                    controls-type="updown"
                                    @input="changeOrder(participant.id)"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="first-name">
                        {{ participant.firstName }}
                    </div>

                    <div class="last-name">
                        {{ participant.lastName }}
                    </div>

                    <div class="email">
                        {{ participant.email }}
                    </div>

                    <div class="section">
                        <select
                            v-model="participant.section_id"
                            @change="updateParticipantSection(participant)"
                        >
                            <option
                                v-for="section in sectionsOptions"
                                :key="section.id"
                                :value="section.id"
                            >
                                {{ section.name }}
                            </option>
                        </select>
                    </div>

                    <div class="active-inactive active-inactive-btns">
                        <app-button
                            :class="{inactive: !+participant.status}"
                            @click="toggleStatus(participant, 1)"
                        >
                            Active
                        </app-button>

                        <app-button
                            :class="{inactive: +participant.status}"
                            @click="toggleStatus(participant, 0)"
                        >
                            Inactive
                        </app-button>
                    </div>

                    <div class="edit-delete edit-delete-align">
                        <app-actions
                            :new-tabs="()=> newTabseditParticipant(participant)"
                            :edit="() => editParticipant(participant)"
                            :trash="() => deleteParticipant(participant)"
                        />
                    </div>
                </div>
                <div class="pagination">
                    <button
                        :disabled="currentPage === 1"
                        @click="prevPage"
                    >
                        Previous
                    </button>
                    <span>
                        Page {{ currentPage }} of {{ totalPages }}
                        <select
                            v-model="pageSize"
                            @change="changePageSize"
                        >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </span>
                    <button
                        :disabled="currentPage === totalPages"
                        @click="nextPage"
                    >
                        Next
                    </button>
                </div>
                <div class="page-numbers">
                    <button
                        v-for="page in displayPages"
                        :key="page"
                        :class="{ active: page === currentPage }"
                        @click="goToPage(page)"
                    >
                        {{ page }}
                    </button>
                </div>
            </div>

            <div class="ext-participants-btns">
                <div class="btn">
                    <app-button
                        tag="router-link"
                        :to="{name: `add-${pathPrefix}-participant`}"
                        class="add-participant-btn"
                    >
                        Add Participants
                    </app-button>
                </div>

                <div
                    v-if="!participantSecurity"
                    class="btn"
                >
                    <app-button
                        class="add-participant-btn"
                        @click="downloadParticipantsData"
                    >
                        Download participants data
                    </app-button>
                </div>

                <div
                    v-if="isExternal"
                    class="btn"
                >
                    <app-button
                        class="add-section-btn"
                        @click="addSection"
                    >
                        Add Section
                    </app-button>
                </div>

                <a
                    v-if="isExternal"
                    :href="templateLink"
                    download="template.xlsx"
                    class="link"
                >
                    Download template
                </a>

                <div
                    v-if="isExternal"
                    class="btn"
                >
                    <app-file-input
                        class="add-section-btn"
                        :load-file="createFromFile"
                        accept=".xls,.xlsx"
                    >
                        Create from file
                    </app-file-input>
                </div>
            </div>
        </div>

        <div
            v-if="sectionsList.length && isExternal"
            class="edit-section"
        >
            <div class="disposer-app-topic-title">
                Edit section
            </div>
            <div
                v-for="(section, index) in sectionsList"
                :key="section.id"
                class="mb-20"
            >
                <div
                    class="app-topic-title"
                    @click="toggleShowSection(section)"
                >
                    <span v-if="section.name">{{ section.name }}</span>
                    <span v-else></span>
                    <span
                        class="icon-collapse"
                        :class="{'collapsed' : !section.show_section}"
                    ></span>
                </div>
                <template>
                    <div
                        v-if="section.show_section"
                        class=""
                    >
                        <app-section
                            :save="() => saveSection(section)"
                            :delete-handler="() => deleteSection(section)"
                        >
                            <div class="section">
                                <div class="section-name">
                                    <label class="disposer-app-label">
                                        Name Section
                                        <input
                                            v-model="section.name"
                                            type="text"
                                            class="disposer-app-input title-input"
                                        />
                                    </label>
                                    <label class="disposer-app-label flex">
                                        <span>Collapsed</span>
                                        <label class="disposer-app-title--checkbox">
                                            <input
                                                v-model="section.collapsed"
                                                :class="{ active : section.collapsed}"
                                                type="checkbox"
                                            />
                                        </label>
                                    </label>
                                    <app-input v-model="section.number" title="Order" width="300px" />
                                </div>

                                <div class="select-participants">
                                    <div>
                                        <label class="disposer-app-checkbox">
                                            <input
                                                type="checkbox"
                                                name="updateAllParticipants"
                                                :checked="checkedAll[index]"
                                                @change="updateAllParticipants(index, section, section.participants)"
                                            />
                                            <div class="box"></div>
                                            <b>Check All</b>
                                        </label>
                                    </div>
                                    <label
                                        v-for="participant in section.participants"
                                        :key="participant.id"
                                        class="disposer-app-checkbox"
                                    >
                                        <input
                                            v-model="participant.status"
                                            type="checkbox"
                                            name="updateMethod"
                                            @change="updateParticipants(section, participant)"
                                        />
                                        <div class="box"></div>
                                        {{ participant.name }}
                                    </label>
                                </div>
                            </div>
                        </app-section>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>


<script>
import eventNav from '@/components/disposer/pages/event/event-nav.vue';
import appSection from '@/components/disposer/app/app-section';
import appButton from '@/components/disposer/app/app-button';
import appActions from '@/components/disposer/app/app-actions';
import AppFileInput from '@/components/disposer/app/app-file-input/index';
import appInput from '@/components/disposer/app/app-input';
import VueNumericInput from 'vue-numeric-input';

import { BASE_URL, generateFileUrl, get, post } from '@/utils/http-helper';
import {
    changeAllPartStatus,
    changePartOrder,
    changePartStatus,
    createPart,
    deleteParticipant,
    deletePartSection,
    savePartSection,
} from '@/utils/disposer/notifications-text-helper';

const DELETE_PARTICIPANT = '/participant/delete';
const SET_ACTIVITY = '/participant/set-activity';
const CHANGE_STATUS = '/participant/change-status';
const CHANGE_ORDER = '/participant/change-order';
const CREATE_FROM_FILE = '/participant/create-from-file';
const CREATE_SECTION = '/section-participant/create';
const UPDATE_SECTION = '/section-participant/update';
const DELETE_SECTION = '/section-participant/delete';
const UPDATE_PARTICIPANTS = '/section-participant/update-participants';
const PARTICIPANTS_LIST_DATA = '/participant/list-data';
const PARTICIPANTS_LIST_DATA_SECTION = 'section-participant/view';
const PARTICIPANTS_LIST_FREE = '/section-participant/free-participants';
const sectionTemplate = (id) => ({
    id,
    name: '',
    collapsed: 0,
    show_section: 1,
});

export default {
    name: 'disposer-participants',

    components: {
        AppFileInput,
        eventNav,
        appSection,
        appButton,
        appActions,
        VueNumericInput,
        appInput,
    },

    data: () => ({
        checkedAll: [],
        participantsList: [],
        sectionsList: [],
        showActivity: false,
        error: '',
        isScroll: false,
        currentPage: 1, // Добавление: текущая страница
        totalPages: 1, // Добавление: общее количество страниц
        pageSize: 10, // Добавление: размер страницы по умолчанию
        query: '', // Добавление: поле для поискового запроса
        sectionsOptions: [],
    }),

    computed: {
        participants() {
            return this.$store.getters['disposerParticipants/get'];
        },

        participantSecurity() {
            return this.$store.getters['platform/platform'].settings.security;
        },

        sections() {
            return this.$store.getters['disposerParticipants/getSections'];
        },

        role() {
            return this.$route.meta.role;
        },

        isExternal() {
            return this.role === 6;
        },

        positionScrollY() {
            return this.$store.getters['disposerParticipants/getUpdateScrollTo'];
        },

        pathPrefix() {
            return this.isExternal ? 'external' : 'internal';
        },

        eventId() {
            return +this.$route.params.id;
        },

        templateLink() {
            return `${BASE_URL}/default/template.xlsx`;
        },
        displayPages() {
            const pages = [];
            if (this.totalPages <= 8) {
                for (let i = 1; i <= this.totalPages; i++) {
                    pages.push(i);
                }
            } else {
                if (this.currentPage < 5) {
                    for (let i = 1; i <= 4; i++) {
                        pages.push(i);
                    }
                    pages.push('...');
                    for (let i = this.totalPages - 3; i <= this.totalPages; i++) {
                        pages.push(i);
                    }
                } else if (this.currentPage > this.totalPages - 4) {
                    for (let i = 1; i <= 4; i++) {
                        pages.push(i);
                    }
                    pages.push('...');
                    for (let i = this.totalPages - 3; i <= this.totalPages; i++) {
                        pages.push(i);
                    }
                } else {
                    pages.push(1, 2, '...');
                    for (let i = this.currentPage - 1; i <= this.currentPage + 1; i++) {
                        pages.push(i);
                    }
                    pages.push('...', this.totalPages - 1, this.totalPages);
                }
            }
            return pages;
        },
    },

    watch: {
        role() {
            this.currentPage = 1;
            this.getParticipantsInfo();
        },
        participantsList(e) {
            if (e.length && this.isScroll) {
                this.$nextTick(() => {
                    window.scrollTo(0, this.positionScrollY);
                    this.isScroll = false;
                });
            }
        },
    },
    beforeRouteEnter(to, from, next) {
        const fromPage = [
            'add-external-participant',
            'add-internal-participant',
            'edit-external-participant',
            'edit-internal-participant',
        ];
        if (fromPage.includes(from.name)) {
            next(vm => {
                vm.isScroll = true;
            });
        }
        next();
    },
    created() {
        this.getParticipantsInfo();
    },
    beforeDestroy() {
        this.$store.commit('disposerParticipants/updateScrollTo', window.scrollY);
    },

    methods: {
        // Метод для обновления секции участника
        updateParticipantSection(participant) {
            // Логика для обновления секции на сервере
            this.$awn.async(post(UPDATE_PARTICIPANTS, {
                event_id: this.eventId,
                section_id: participant.section_id,
                user_id: participant.id,
                status: +participant.status,
                force: 1,
            }), response => {
                if (response.status) {
                    this.$awn.success(savePartSection);
                } else {
                    participant.status = false;
                }
            });
        },

        async toggleShowSection(section) {
            if (!section.show_section) {
                this.sectionsList.forEach((section) => {
                    section.show_section = false;
                });
                await this.$awn.async(get(PARTICIPANTS_LIST_DATA_SECTION, {
                    id: section.id,
                }), response => {
                    section.participants = response.data.participants;
                    section.show_section = !section.show_section;
                });
            } else {
                section.show_section = !section.show_section;
            }
        },
        async getParticipantsInfo() {
            await this.$store.dispatch('disposerParticipants/get', {
                eventId: this.eventId,
                role: this.role,
                size: this.pageSize, // Использование текущего размера страницы
                page: this.currentPage,
                query: this.query, // Добавление: передача поискового запроса
            });
            await this.$store.dispatch('disposerParticipants/getSections', {
                eventId: this.eventId,
                type: this.role,
            });
            this.participantsList = this.participants.participants.map(p => {
                p.order = +p.order;
                return p;
            });
            this.showActivity = this.participants.show_activity;
            this.sectionsList = this.sections;
            this.sectionsOptions = this.sections;
            this.sectionsList.forEach(() => {
                this.checkedAll.push(false);
            });

            // Установка общего количества страниц после получения данных
            this.totalPages = this.participants.total_page;
        },

        async nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                await this.getParticipantsInfo();
            }
        },

        async prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                await this.getParticipantsInfo();
            }
        },

        async changePageSize() {
            this.currentPage = 1; // Переход к первой странице при изменении размера страницы
            await this.getParticipantsInfo();
        },
        async goToPage(page) {
            if (page !== this.currentPage && page >= 1 && page <= this.totalPages) {
                this.currentPage = page;
                await this.getParticipantsInfo();
            }
        },

        async searchParticipants(clear = false) {
            if (clear) {
                this.query = '';
            }
            this.currentPage = 1; // Сброс страницы при поиске
            await this.getParticipantsInfo();
        },
        async toggleAllStatus(status) {
            this.$awn.async(post(CHANGE_STATUS, {
                event_id: this.eventId,
                status,
                type: this.role,
            }), response => {
                if (response.status) {
                    this.participantsList = this.participantsList.map(p => {
                        p.status = status;
                        return p;
                    });
                    this.$awn.success(changeAllPartStatus);
                }
            });
        },

        async addSection() {
            const newSection = { ...sectionTemplate(Math.random()) };
            await this.$awn.async(get(PARTICIPANTS_LIST_FREE, {
                event_id: this.eventId,
            }), response => {
                newSection.participants = response.data.participants.map(p => {
                    return {
                        id: p.id,
                        status: +p.status,
                        name: p.name,
                    };
                });
                this.sectionsList.push(newSection);
            });
        },

        createFromFile(file) {
            const fd = new FormData;
            fd.append('file', file);
            fd.append('event_id', this.eventId);

            this.$awn.async(
                post(CREATE_FROM_FILE, fd),
                (response) => {
                    if (response.data.file) {
                        window.open(generateFileUrl(response.data.file), '_blank');
                        this.getParticipantsInfo();
                        this.$awn.success(createPart);
                    }
                },
            );
        },

        async saveSection(section) {
            if (section.id >= 1) {
                this.renameSection(section);
                return;
            }
            const obj = {
                event_id: this.eventId,
                name: section.name,
                number: section.number,
                collapsed: +section.collapsed,
                show_section: 0,
            };
            obj.participants = section.participants.map(p => {
                return {
                    user_id: p.id,
                    status: +p.status,
                };
            });
            this.$awn.async(post(CREATE_SECTION, obj), response => {
                if (response.status) {
                    section.id = response.data.id;
                    this.$awn.success(savePartSection);
                    this.getParticipantsInfo();
                }
            });
        },
        async deleteSection(section) {
            if (section.id >= 1) {
                this.$awn.async(post(DELETE_SECTION, {
                    id: section.id,
                }), response => {
                    if (response.status) {
                        this.sectionsList = this.sectionsList.filter(s => s.id !== section.id);
                        this.$awn.success(deletePartSection);
                    }
                });
            }
            this.sectionsList = this.sectionsList.filter(s => s.id !== section.id);
        },
        renameSection(section) {
            this.$awn.async(post(UPDATE_SECTION, {
                id: section.id,
                name: section.name,
                number: section.number,
                collapsed: +section.collapsed,
                show_section: 0,
            }), response => {
                if (response.status) {
                    this.$awn.success(savePartSection);
                }
            });
        },
        updateAllParticipants(index, section, participants) {
            let select = this.checkedAll[index];
            participants.forEach((participant) => {

                participant.status = !select;
                this.updateParticipants(section, participant);

            });
            this.checkedAll[index] = !select;
        },
        updateParticipants(section, participant) {
            if (section.id < 1) {
                return;
            }
            this.$awn.async(post(UPDATE_PARTICIPANTS, {
                event_id: this.eventId,
                section_id: section.id,
                user_id: participant.id,
                status: +participant.status,
            }), response => {
                if (response.status) {
                    this.$awn.success(savePartSection);
                    this.getParticipantsInfo();
                } else {
                    participant.status = false;
                }
            });
        },
        newTabseditParticipant(participant) {
            let routeData = this.$router.resolve({
                name: `edit-${this.pathPrefix}-participant`,
                params: { participantId: participant.id },
            });
            window.open(routeData.href, '_blank');
        },
        editParticipant(participant) {
            this.$router.push({
                name: `edit-${this.pathPrefix}-participant`,
                params: { participantId: participant.id },
            });
        },

        async deleteParticipant(participant) {
            this.$awn.async(post(DELETE_PARTICIPANT, {
                event_id: this.eventId,
                id: +participant.id,
            }), response => {
                if (response.status) {
                    this.participantsList = this.participantsList.filter(p => +p.id !== +participant.id);
                    this.$awn.success(deleteParticipant);
                }
            });
        },
        async toggleStatus(participant, status) {
            this.$awn.async(post(CHANGE_STATUS, {
                id: +participant.id,
                status: +status,
                event_id: this.eventId,
                type: this.role,
            }), response => {
                if (response.status) {
                    participant.status = status;
                    this.$awn.success(changePartStatus);
                }
            });
        },
        toggleParticipantsActiveTime() {
            post(SET_ACTIVITY, {
                event_id: this.eventId,
                type: this.role,
                status: +this.showActivity,
            });
        },
        changeOrder(id) {
            clearTimeout(window.CHANGE_ORDER_TM);
            window.CHANGE_ORDER_TM = setTimeout(() => {
                const index = this.participantsList.findIndex(p => +p.id === +id);
                this.$awn.async(post(CHANGE_ORDER, {
                    id,
                    order: this.participantsList[index].order,
                }), response => {
                    if (response.status) {
                        this.$awn.success(changePartOrder);
                    }
                });
            }, 1000);
        },

        downloadParticipantsData() {
            this.$awn.async(get(PARTICIPANTS_LIST_DATA, {
                event_id: this.eventId,
                type: this.isExternal ? 6 : 5,
            }), response => {
                if (response.status) {
                    window.open(generateFileUrl(response.data.file), '_blank');
                    this.$awn.success('Success');
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">

.icon-collapse {
    position: relative;
    margin-left: 0;
    display: block;
    //width: 20px;
    //height: 10px;
    //background: url('../../../assets/img/icons/disposer/bottom-arrow_icon.svg') center center / contain no-repeat;
    transform: scale(1, -1);
    font-size: 150%;
    line-height: 0.5;
}

.icon-collapse:after {
    content: '−';
}

.icon-collapse.collapsed:after {
    //transform: scale(1, 1);
    content: '+'
}

.app-topic-title {
    justify-content: space-between;
    cursor: pointer;
}

.mb-20 {
    margin-bottom: 20px;

    .app-topic-title {
        background: rgba(34, 45, 48, 0.4);
    }
}

.search-bar {
    margin-left: auto;
    position: relative;
    width: 100%;
    max-width: 420px;

    input {
        padding-right: 30px;
    }

    span {
        position: absolute;
        right: 10px;
        top: 3px;
        cursor: pointer;
    }
}

.section {
    select {
        max-width: 150px;
    }
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1em 0;

    button {
        background-color: var(--platform-main-color);
        border: none;
        color: white;
        padding: 0.5em 1em;
        margin: 0 0.5em;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover:not(:disabled) {
            background-color: var(--platform-accent-color);
        }

        &:disabled {
            background-color: #d6d6d6;
            cursor: not-allowed;
        }
    }

    span {
        display: flex;
        align-items: center;
        font-size: 1em;

        select {
            margin-left: 0.5em;
            padding: 0.2em;
            border-radius: 5px;
            border: 1px solid #ccc;
            cursor: pointer;
        }
    }
}

.page-numbers {
    display: flex;
    justify-content: center;
    margin: 1em 0;

    button {
        background-color: #f8f9fa;
        border: 1px solid var(--platform-main-color);
        color: var(--platform-accent-color);
        padding: 0.5em 1em;
        margin: 0 0.2em;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;

        &.active {
            background-color: var(--platform-main-color);
            color: white;
        }

        &:hover:not(.active) {
            background-color: var(--platform-main-color);
            color: white;
        }
    }
}

.disposer-app-label.flex {
    display: flex;
    align-items: center;
}

.disposer-participants {
    .main {
        .participants-table {
            padding: 20px 0;

            .row-tr.row-tr-head {
                background-color: #d0d1d2;
            }

            .row-tr {
                display: flex;
                justify-content: space-between;
                align-items: center;

                width: to_rem(1300px);
                //max-width: 78vw;
                height: auto;
                padding: 10px;

                background-color: white;
                border: 1px solid #d0d1d2;

                .number {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    width: to_rem(50px);

                    input {
                        width: 50px;
                        height: 35px;
                        margin-top: 0;
                    }
                }

                .first-name {
                    width: to_rem(210px);
                }

                .last-name {
                    width: to_rem(210px);
                }

                .email {
                    width: to_rem(150px);

                    word-break: break-word;
                }

                .active-inactive {
                    width: to_rem(200px);
                }

                .edit-delete {
                    width: to_rem(90px);
                }

                .active-inactive-btns {
                    display: flex;

                    padding-top: 5px;

                    .app-button {
                        width: 80px;
                        height: 35px;
                        padding: 5px;
                    }

                    .inactive {
                        background-color: #d0d1d2;
                    }
                }

                .active-inactive-btns-head {
                    display: flex;

                    padding-top: 5px;

                    .app-button {
                        width: 77.5px;
                        height: 35px;
                        margin-right: 5px;
                        padding: 5px;
                    }
                }

                .edit-delete-align {
                    display: flex;
                    justify-content: flex-end;

                    padding-right: 10px;
                }
            }
        }

        .ext-participants-btns {
            display: flex;
            align-items: center;

            margin-bottom: to_rem(24px);

            .link {
                margin-right: to_rem(48px);
                margin-left: auto;

                color: #222d30;
                text-decoration: underline;
            }

            .btn {
                &:not(:last-of-type) {
                    margin-right: to_rem(24px);
                }
            }

            &.top {
                margin-top: to_rem(24px);
            }
        }
    }

    .edit-section {
        padding: 30px 0;

        .select-participants {
            padding: 30px 0;

            .disposer-app-checkbox {
                margin-bottom: 0.5rem;
            }
        }
    }

    .number-input {
        .vue-numeric-input {
            width: 70px !important;
        }
    }
}
</style>
